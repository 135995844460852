import React from 'react';
import BannerComponent from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavbarComponent from '../Component/Navbar/Navbar';
import RightColumForm from '../Component/RightColumForm';

function IeltsSchore() {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent title="IELTS Score" text="IELTS" />
      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-lg-7 col-md-12 mb-4">
            <h2 className="section-title" style={{ textAlign: "justify" }}>
              IELTS Score Requirements for Higher Study
            </h2>
            <p className="section-text">
              The IELTS (International English Language Testing System) is one
              of the most popular English proficiency tests globally, required
              by universities, employers, and immigration authorities in
              English-speaking countries. It measures a candidate's ability to
              communicate in English across all four language skills: listening,
              reading, writing, and speaking. Over 2.9 million people take IELTS
              annually to fulfill requirements for education, work, or
              migration.
            </p>
            <h2 className="section-title" style={{ textAlign: "justify" }}>
              What is IELTS?
            </h2>
            <p className="section-text">
              IELTS, the International English Language Testing System, is
              designed to assess the language ability of candidates who need to
              study or work where English is the language of communication.
              IELTS is jointly managed by the University of Cambridge ESOL
              Examinations (Cambridge ESOL), British Council and IDP: IELTS
              Australia. IELTS conforms to the highest international standards
              of language assessment. IELTS is recognised by universities and
              employers in many countries, including Australia, Canada, New
              Zealand, the UK and the USA. It is also recognised by professional
              bodies, immigration authorities and other government agencies.
            </p>
            <table className="table table-bordered">
              <thead className="bg-primary text-white">
                <tr style={{ color: "black" }}>
                  <th scope="col">Country</th>
                  <th scope="col">Bachelor</th>
                  <th scope="col">Master's</th>
                  <th scope="col">PhD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Australia</td>
                  <td>IELTS - 6.0 (No Band Below 5.5)</td>
                  <td>6.5 IELTS No Band Below 6.0</td>
                  <td>6.5</td>
                </tr>
                <tr>
                  <td>Austria</td>
                  <td>IELTS - 6.0 (No Band Below 5.5)</td>
                  <td>6.5 IELTS No Band Below 6.0</td>
                  <td>7.0</td>
                </tr>
                <tr>
                  <td>Canada</td>
                  <td> With/without IELTS or ESL 6.5/6.0/ No Band Below 5.0</td>
                  <td>6.5 IELTS No Band Below 6.0</td>
                  <td>7.5/7.0</td>
                </tr>
                <tr>
                  <td>China</td>
                  <td>IELTS Not Required</td>
                  <td>IELTS Not Required</td>
                  <td>IELTS Not Required</td>
                </tr>
                <tr>
                  <td>Cyprus</td>
                  <td>IELTS 6.0/5.5/ No Band Below 5.0</td>
                  <td>6.0 IELTS No Band Below 5.5</td>
                  <td>7.0/6.5</td>
                </tr>
                <tr>
                  <td>Czech Republic</td>
                  <td>IELTS - 6.0/ 5.5</td>
                  <td>IELTS - 6.0/ 5.5</td>
                  <td>IELTS - 6.0/ 5.5</td>
                </tr>
                <tr>
                  <td>Denmark</td>
                  <td>IELTS - 6.0 (No Band Below 5.5)</td>
                  <td>6.5 IELTS</td>
                  <td>7.0/6.5</td>
                </tr>
                <tr>
                  <td>Finland</td>
                  <td>IELTS - 6.0</td>
                  <td>6.5 IELTS</td>
                  <td>7.0</td>
                </tr>
                <tr>
                  <td>Germany</td>
                  <td>
                    Bachelor - 6.0 German Language - A1 - A2 Full Free
                    Scholarship
                  </td>
                  <td>6.5 IELTS</td>
                  <td>7.0/6.5</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Right Column - Contact Form */}
         <RightColumForm/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default IeltsSchore
