import React from 'react';
import BannerComponent from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavbarComponent from '../Component/Navbar/Navbar';
import RightSideFrom from '../Component/RightColumForm'

function StudyTips() {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent title="Study Tips" text="Study Tips" />

      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Navigating Academic Success: Essential Study Tips for
              International Students Living Abroad
            </h2>
            <p className="section-text">
              Studying in a foreign country offers unique opportunities to
              experience new cultures, expand perspectives, and access
              world-class education. However, adapting to a new academic
              environment while managing life abroad can present challenges. To
              ensure success, international students should adopt strategies
              that enhance both learning and adjustment to their new
              surroundings. Here are some essential tips:
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Create a structured study schedule
            </h4>
            <p className="section-text">
              Adjusting to a new routine can be overwhelming. Establishing a
              structured study plan helps create stability and ensures that
              study time, classes, and personal activities are well-balanced.
              Set specific times for studying, attending classes, and leisure
              activities, and stick to your schedule to maintain consistency.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Adopt effective note taking techniques:
            </h4>
            <p className="section-text">
              Different cultures often have distinct teaching styles. To keep
              up, explore various note-taking techniques, such as using digital
              tools, creating mind maps, or taking concise, organized notes.
              Engaging actively in the note-taking process enhances
              understanding of the material and helps with exam preparation.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Seeking Peer Support
            </h4>
            <p className="section-text">
              Study groups with fellow international or local students are
              invaluable. Collaborating with peers allows for the exchange of
              ideas, clarifying doubts, and gaining new perspectives. Group
              discussions also deepen understanding and offer a sense of
              community.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Use Campus Resources
            </h4>
            <p className="section-text">
              Most universities offer resources specifically designed to support
              students, including international students. Make the most of
              writing centers, tutoring services, and academic workshops to help
              develop crucial academic skills, such as essay writing, research
              techniques, and exam strategies.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Develop Language Skills
            </h4>
            <p className="section-text">
              Language barriers can be a challenge in both academic and social
              settings. Engaging in language development programs, joining
              language exchange groups, and attending language classes can
              improve language proficiency. Additionally, reading local
              newspapers, books, or online portals will help you become more
              comfortable with the local language.
            </p>
          </div>

          {/* Right Column - Contact Form */}
          <RightSideFrom />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StudyTips
