import React from 'react';
import BannerComponent from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavbarComponent from '../Component/Navbar/Navbar';
import RightColumForm from '../Component/RightColumForm';

function WhatToPack() {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent title="What to Pack" text="What to Pack" />
      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Essential Packing Guide for International Students Going to Study
              Abroad
            </h2>
            <p className="section-text">
              As students embark on their academic and cultural journey abroad,
              packing efficiently is essential to ensure a smooth transition.
              Here's a comprehensive guide to help you prepare:
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Important Documents
            </h4>
            <p className="section-text">
              Passport, Student Visa, Acceptance Letter: Ensure all necessary
              travel and admission documents are ready. <br /> Photocopies &
              Digital Backups: Make copies of important documents and store them
              separately. Save digital copies on a cloud platform for easy
              access.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Clothing
            </h4>
            <p className="section-text">
              Weather-Appropriate Clothing: Research the climate of your
              destination. Pack a variety of outfits for different seasons,
              including T-shirts, jeans, jackets, and weather-specific items.
              <br />
              Formal Attire: Bring at least one set of formal clothing for
              presentations, events, or professional occasions. <br />{" "}
              Comfortable Shoes: Prioritize comfort with everyday walking shoes
              and formal footwear.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Personal care products
            </h4>
            <p className="section-text">
              Toiletries: Pack essentials like toothpaste, shampoo, soap,
              deodorant, and other items for personal hygiene, especially for
              the first few weeks. Travel-size containers can save space. <br />
              Specialty Products: If you have specific skincare or haircare
              needs, bring these with you as they may be harder to find abroad.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Medicines and First Aid
            </h4>
            <p className="section-text">
              Prescription Medications: Bring an adequate supply along with your
              doctor's prescription and a note in case you need refills or
              medical assistance abroad. <br /> First Aid Kit: Include basic
              items like pain relievers, bandages, antiseptic creams, and any
              over-the-counter medications you regularly use.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Necessary Gadgets
            </h4>
            <p className="section-text">
              Laptop, Smartphone, and Tablet: These are crucial for study,
              research, and communication. <br /> Chargers, Adapters, Power
              Bank: Ensure you have the correct power adapters for your
              destination country, along with extra chargers and a power bank
              for long days.
            </p>
          </div>

          {/* Right Column - Contact Form */}
          <RightColumForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default WhatToPack
