import React from 'react';
import BannerComponent from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavbarComponent from '../Component/Navbar/Navbar';
import RightColumForm from '../Component/RightColumForm';

function Developing() {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent
        title="Developing your Skills"
        text="Developing your Skills"
      />

      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Developing Skills While Studying Abroad: A Transformative Journey
            </h2>
            <p className="section-text">
              Studying abroad offers more than academic growth—it is a unique
              opportunity to develop a wide range of personal and professional
              skills. As students embark on this transformative journey, they
              not only enhance their knowledge but also acquire essential life
              skills that will benefit them for years to come.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Cultural adaptability:
            </h4>
            <p className="section-text">
              Embrace Diversity: Living in a foreign country exposes students to
              diverse cultural contexts, pushing them to adapt and respect
              different customs and traditions. This promotes empathy,
              tolerance, and a broader worldview, helping students navigate
              unfamiliar social and cultural settings with ease.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Communication skills:
            </h4>
            <p className="section-text">
              Linguistic and Non-verbal Communication: Immersing yourself in a
              new language improves both verbal and non-verbal communication
              skills. Along with mastering a foreign language, students also
              learn to interpret gestures, body language, and expressions, which
              are crucial in cross-cultural interactions.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Independence and self-reliance:
            </h4>
            <p className="section-text">
              Step Out of the Comfort Zone: Studying abroad often means being
              away from family and friends, teaching students to rely on
              themselves. This fosters independence, self-discipline, and
              confidence, all of which are essential for personal growth and
              success in unfamiliar environments.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Problem-solving:
            </h4>
            <p className="section-text">
              Overcome Challenges: From managing finances to dealing with visa
              issues, students encounter various challenges while living abroad.
              These experiences strengthen problem-solving abilities, helping
              them develop creative solutions to unforeseen obstacles.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Time management:
            </h4>
            <p className="section-text">
              Balance Multiple Responsibilities: Juggling academics, part-time
              work, extracurricular activities, and personal life requires
              efficient time management. Learning to prioritize tasks and manage
              time effectively is crucial for succeeding in a new environment.
            </p>
          </div>

          {/* Right Column - Contact Form */}
          <RightColumForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Developing
