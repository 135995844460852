import React from 'react';
import BannerComponent from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavbarComponent from '../Component/Navbar/Navbar';
import RightColumForm from '../Component/RightColumForm';

function Networking() {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent
        title="Networking & Socialising"
        text="Networking & Socialising"
      />
      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Networking and Socializing: The Keys to a Fulfilling Study Abroad
              Experience
            </h2>
            <p className="section-text">
              Studying abroad is more than just an academic journey; it's a
              life-changing experience that offers countless opportunities for
              personal, cultural, and professional growth. Networking and
              socializing can enhance your study abroad experience, opening
              doors you may never have imagined.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Career Progression:
            </h4>
            <p className="section-text">
              Networking with professors, fellow students, and professionals in
              your field of interest can help you gain access to local
              industries, corporate trends, and job opportunities. These
              connections can lead to internships, part-time jobs, and even
              post-graduation employment in your host country.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Cultural exchange:
            </h4>
            <p className="section-text">
              Expand Your Worldview: Socializing with people from diverse
              cultures can enrich your global perspective and improve your
              understanding of international issues. Cultural exchanges can also
              help you adapt better to new environments and approach challenges
              with greater confidence.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Academic Enrichment:
            </h4>
            <p className="section-text">
              Collaborate and Learn: Networking with professors and peers can
              lead to collaborative research projects, academic discussions, and
              access to valuable resources. Engaging in such activities will not
              only deepen your knowledge but also help you excel in your
              studies.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Attend Social Events:
            </h4>
            <p className="section-text">
              Engage in Campus Life: Universities often host social events like
              welcome parties, cultural festivals, and networking mixers.
              Attending these gatherings helps you expand your social circle,
              build connections, and even find mentors who can guide your
              academic or career path.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Join clubs and organizations :
            </h4>
            <p className="section-text">
              Pursue Interests and Leadership Roles: Joining student clubs,
              sports teams, or extracurricular groups helps you meet people with
              shared interests. It's also a great way to develop leadership
              skills and create lasting friendships that go beyond your time
              abroad.
            </p>
          </div>

          {/* Right Column - Contact Form */}
          <RightColumForm />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Networking
