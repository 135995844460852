import React from 'react';
import BannerComponent from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavbarComponent from '../Component/Navbar/Navbar';
import RightColumForm from '../Component/RightColumForm';

function GuidStudyAbroad() {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent
        title="FSC Guide to Study Abroad"
        text="FSC Guide Line"
      />
      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              The Ultimate Study Abroad Guide: Experts Tips from the best Study
              Abroad Agency in Bangladesh
            </h2>
            <p className="section-text">
              Planning your study abroad journey is a significant life decision.
              Working with experts can help ensure a smooth and successful
              transition.FSC is your trusted guide through every step of the
              process, from planning to settling into your new environment.
              Here’s how we can help:
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Focusing on Goals
            </h4>
            <p className="section-text">
              The first and most important step is defining your study abroad
              goals. Why do you want to study abroad? What do you hope to
              achieve? AtFSC, we help you clarify these goals and match them
              with the right programs and destinations, ensuring your study
              abroad experience aligns with your aspirations.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Researching Universities and Programs
            </h4>
            <p className="section-text">
              FSC has partnerships with top universities and educational
              institutions worldwide, including Canada, Australia, the USA, and
              more. We guide students to find the perfect match for their
              academic and career goals, offering access to a wide range of
              programs tailored to their interests.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Assist in Financial Planning
            </h4>
            <p className="section-text">
              Studying abroad often comes with financial challenges, but FSC
              Global Network can assist in navigating these complexities. We
              provide expert advice on scholarships, grants, and loans, helping
              you create a realistic budget for living and studying abroad. We
              ensure you have the necessary information to manage your finances
              effectively.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Visa and Documentation Assistance
            </h4>
            <p className="section-text">
              Navigating visa applications and required documentation can be a
              daunting task. With years of experience in handling student visa
              processes, FSC ensures that students are well-prepared with the
              right paperwork, guidance on interviews, and an understanding of
              visa requirements for their chosen destination.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Preparing for Cultural Adjustment
            </h4>
            <p className="section-text">
              Studying abroad is not just about academics—adjusting to a new
              culture is part of the journey. FSC offers cultural orientation
              sessions to help students integrate into their host country. Our
              experts provide tips on overcoming culture shock and making the
              most of your experience from day one.
            </p>
            <p className="section-text">
              With FSC by your side, you'll be equipped with the knowledge,
              resources, and confidence to turn your study abroad dreams into
              reality. Let us guide you toward academic excellence and an
              enriching global experience.
            </p>
          </div>

          {/* Right Column - Contact Form */}
          <RightColumForm/>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default GuidStudyAbroad
