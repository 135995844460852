import React from 'react';
import Banner from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import Navbar from '../Component/Navbar/Navbar';
import RightColumForm from '../Component/RightColumForm';

function BenifitStudyAbroad() {
  return (
    <div>
      <Navbar />
      <Banner
        title="Study Abroad Benefits
"
        text="Benefits"
      />

      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Unlocking the World: The Life-Changing Benefits of Studying
              Foreign Country
            </h2>
            <p className="section-text">
              Studying in a foreign country offers a transformative experience
              that blends education, adventure, and personal growth. This
              journey breaks cultural and academic boundaries, unlocking a world
              of opportunities and enriching students’ lives in countless ways.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Cultural Immersion
            </h4>
            <p className="section-text">
              One of the most profound benefits of studying abroad is the chance
              to immerse yourself in a different culture. Living and studying in
              a foreign country gives students a unique perspective on daily
              life, customs, and traditions, fostering greater cultural
              awareness and appreciation.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Language Proficiency
            </h4>
            <p className="section-text">
              Studying abroad provides an ideal environment for improving
              language skills. Engaging with native speakers and handling
              everyday situations in a different language can accelerate
              linguistic growth, boosting communication skills and opening doors
              to global opportunities.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Personal Growth
            </h4>
            <p className="section-text">
              Leaving the comfort of home to study in a foreign country is a
              journey of self-discovery and growth. Students develop resilience,
              independence, and adaptability, which help them overcome
              challenges, build confidence, and gain essential life skills.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Global Networking
            </h4>
            <p className="section-text">
              Studying abroad introduces you to people from various backgrounds,
              including fellow international students and local peers. These
              interactions lead to lifelong friendships and valuable
              professional connections. Networking on a global scale can enhance
              career prospects and open doors to international job markets.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Career Advancement
            </h4>
            <p className="section-text">
              Employers increasingly value the qualities developed during a
              study abroad experience, such as adaptability, cross-cultural
              communication, and a global mindset. Having international study
              experience on your resume sets you apart, making you more
              competitive in a diverse job market.
            </p>
            <p className="section-text">
              Studying abroad is more than just an academic pursuit; it’s an
              investment in personal and professional development that can have
              long-lasting benefits in all areas of life.
            </p>
          </div>

          {/* Right Column - Contact Form */}
          <RightColumForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BenifitStudyAbroad
