import React from 'react';
import BannerComponent from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavbarComponent from '../Component/Navbar/Navbar';
import RightColumForm from '../Component/RightColumForm';

function JobSeeking() {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent title="Job Seeking Tips" text="Job Seeking Tips" />

      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Navigating the Job Search While Studying Abroad: Essential Tips
              for Success
            </h2>
            <p className="section-text">
              Studying abroad not only offers an enriching cultural and academic
              experience but also opens doors to global career opportunities.
              Whether you're searching for part-time work during your studies or
              planning for post-graduation employment in your host country,
              having a strategic job search approach is key. Here are some
              essential tips to help you navigate the job market successfully:
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Start early and be proactive:
            </h4>
            <p className="section-text">
              Prepare Before Arrival: Begin your job search early, even before
              arriving in your host country. Research local job prospects,
              industries, and market trends to identify potential opportunities.
              Utilize university career services, attend job fairs, and seek out
              networking events to make valuable connections.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Understand visa and work regulations:
            </h4>
            <p className="section-text">
              Know Your Rights: Every country has specific rules regarding
              employment for international students. Be sure to familiarize
              yourself with the visa requirements and work regulations in your
              host country to understand the types of jobs you're eligible for,
              how many hours you're allowed to work, and the legal documentation
              needed.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Create an impressive resume and cover letter:
            </h4>
            <p className="section-text">
              Tailor Your Application: Craft an updated and professional resume
              and cover letter that highlight your relevant skills, experience,
              and qualifications. Emphasize any international experience,
              multilingual abilities, and cultural competence, as these can make
              you stand out to employers. Customize your resume for each job to
              show that you understand the company’s needs.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Use university resources:
            </h4>
            <p className="section-text">
              Leverage Career Services: Many universities offer comprehensive
              career support, including guidance on resume writing, interview
              preparation, and job search strategies. Take full advantage of
              these services. University career counselors often have inside
              knowledge of the local job market and can help connect you with
              potential employers.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Network actively:
            </h4>
            <p className="section-text">
              Networking is Key: Building a professional network is one of the
              most effective ways to find job opportunities. Attend university
              events, workshops, and industry-specific seminars. Engage with
              professors, fellow students, and local professionals.
              Additionally, use professional networking platforms like LinkedIn
              to build relationships with industry leaders and recruiters.
            </p>
          </div>

          {/* Right Column - Contact Form */}
          <RightColumForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default JobSeeking
