import React from 'react';

function RightColumForm() {
  return (
    <div className="col-md-5">
      <div className="card p-4 shadow-sm">
        <div className="" style={{ color: "black" }}>
          <h4 className="section-title">
            Interested in studying abroad with FSC?
          </h4>
          <p className="section-text">
            Enter your details below and we'll call you back when it suits you.
          </p>
        </div>

        <form>
          <div className="mb-3">
            <label htmlFor="fullName" className="form-label section-text">
              Full Name
            </label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              placeholder="Full Name"
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="phoneNumber" className="form-label section-text">
              Phone Number
            </label>
            <input
              type="tel"
              className="form-control"
              id="phoneNumber"
              placeholder="Phone Number"
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label section-text">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="destination" className="form-label section-text">
              Your preferred study destination
            </label>
            <select
              className="form-select section-text"
              id="destination"
              required
            >
              <option>Please Select Destination</option>
              <option value="usa">USA</option>
              <option value="uk">UK</option>
              <option value="australia">Australia</option>
              <option value="canada">Canada</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="message" className="form-label section-text">
              Message
            </label>
            <textarea
              className="form-control"
              id="message"
              rows="3"
              placeholder="Message"
            ></textarea>
          </div>

          <button type="submit" className="btn btn-warning w-100 section-text">
            Submit Now
          </button>
        </form>
      </div>
    </div>
  );
}

export default RightColumForm
