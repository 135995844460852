import React from 'react';
import BannerComponent from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavbarComponent from '../Component/Navbar/Navbar';
import RightColumForm from '../Component/RightColumForm';

function Schollarship() {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent title="Scholarship" text="Scholarship" />

      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Scholarships for International Student
            </h2>
            <p className="section-text">
              FSC is the first organization in Bangladesh which born to fulfill
              ever growing demand for higher education in Bangladesh. This
              organization is guided by renowned local and international
              university, educationists. BSB provides guidance and assistances
              to Bangladesh student for full and partial International
              Scholarship to complete their Higher Education in abroad. At BSB,
              highly skilled and professional student advisors guide students to
              find out latest, comprehensive list of scholarship, grants and
              other educational awards provided by different international
              organizations and universities.
            </p>
            <div className="row">
              {/* Scholarship List Section */}
              <div className="col-lg-12 col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="section-title">USA</h5>
                    <hr />
                    <h6 className="section-text">Government-funded</h6>
                    <ul>
                      <li className="section">
                        Fulbright Foreign Student Program
                      </li>
                      <li className="section">
                        Hubert Humphrey Fellowship Program
                      </li>
                    </ul>
                    <h6 className="section-text">Non-governmental/Private</h6>
                    <ul>
                      <li className="section">
                        The Next Gen Scholarship Fund
                      </li>
                      <li className="section">
                        Tortuga Backpacks Study Abroad Scholarship
                      </li>
                      <li className="section">
                        David P. Shapiro Annual Leukaemia Scholarship
                      </li>
                      <li className="section">
                        University Entrance Scholarships
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <h5 className="section-title">UK</h5>
                    <hr />
                    <h6 className="section-text">Government-funded</h6>
                    <ul>
                      <li className="section">
                        British Chevening Scholarships
                      </li>
                      <li className="section">
                        Commonwealth Scholarships for Developing Commonwealth
                        Countries
                      </li>
                      <li className="section">
                        Commonwealth Shared Scholarship Scheme at UK
                        Universities
                      </li>
                    </ul>
                    <h6 className="section-text">Non-governmental</h6>
                    <ul>
                      <li className="section">Euraxess UK</li>
                      <li className="section">CastleSmart Scholarship</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Contact Form */}
          <RightColumForm/>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Schollarship
