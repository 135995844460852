import React from 'react';
import Banner from '../BannerComponent';
import Footer from '../Footer/Footer';
import NavbarComponent from '../Navbar/Navbar';
import RightColumForm from '../RightColumForm';



function ManagingMoney() {
  return (
    <div>
      <NavbarComponent />
      <Banner title="Managing Money" text="Managing Money" />
      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Managing Money Wisely While Studying Abroad: Tips for Success
            </h2>
            <p className="section-text">
              Studying abroad is an incredible opportunity to explore new
              cultures, gain diverse experiences, and broaden your perspective.
              However, managing your finances effectively while away from home
              is crucial for ensuring a stress-free experience. Here are some
              essential tips to help you manage money wisely during your study
              abroad journey.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Create a Budget:
            </h4>
            <p className="section-text">
              Plan Ahead: Before leaving for your study abroad program, create a
              detailed budget that outlines all expected sources of income and
              expenses. Be realistic and include costs for tuition,
              accommodation, food, transportation, and leisure activities. A
              clear budget will help you track your spending and prevent
              financial surprises.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Research Costs in Advance:
            </h4>
            <p className="section-text">
              Know Your Host Country's Cost of Living: Research the cost of
              living in your host country before arriving. Look up the average
              prices for essentials such as groceries, transportation, and
              dining out. Knowing the typical expenses in advance will allow you
              to set a more accurate budget
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Open a Local Bank Account:
            </h4>
            <p className="section-text">
              Avoid Fees: Setting up a local bank account in your host country
              can help you avoid costly currency conversion fees and ATM
              withdrawal charges. Many universities partner with local banks to
              offer special accounts for international students, making it
              easier and cheaper to manage your money.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Use a Currency Exchange App:
            </h4>
            <p className="section-text">
              Monitor Exchange Rates: Use currency exchange apps to track the
              value of your home currency in comparison to the local currency.
              By monitoring exchange rates, you can time your currency exchanges
              for when the rates are most favorable, helping you save money.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Credit Cards and Debit Cards:
            </h4>
            <p className="section-text">
              Notify Your Bank: Before traveling, notify your bank of your plans
              to study abroad to avoid your card being flagged for suspicious
              activity. It's also worth considering a credit card that does not
              charge foreign transaction fees, which can save you a significant
              amount when making purchases abroad.
            </p>
          </div>

          {/* Right Column - Contact Form */}
          <RightColumForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ManagingMoney
