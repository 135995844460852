import React from 'react';
import BannerComponent from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavbarComponent from '../Component/Navbar/Navbar';
import RightColumForm from '../Component/RightColumForm';

function UniversityRanking() {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent title="University Ranking" text="University Ranking" />

      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Online Updates Coming Soon....
            </h2>
          </div>

          {/* Right Column - Contact Form */}
          <RightColumForm/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UniversityRanking
