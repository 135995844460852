import React from 'react'
// import Navbar from '../Component/Navbar/Navbar'
// import Banner from '../Component/BannerComponent'
import BannerComponent from '../Component/BannerComponent'
import Footer from '../Component/Footer/Footer'
import NavbarComponent from '../Component/Navbar/Navbar'
import RightColumForm from '../Component/RightColumForm'

const FindUniversity = () => {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent title="Find a University" text=" Find a University" />
      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-lg-7 col-md-12 mb-4">
            <table className="table table-bordered">
              <thead className="bg-primary text-white">
                <tr style={{ color: "black" }}>
                  <th className="section-title" scope="col">
                    Country
                  </th>
                  <th className="section-title" scope="col">
                    Name of Universities
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="section-text">Australia</td>
                  <td className="section-text">
                    Cambridge International College, Melbourne
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">Curtin University</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">RMIT University</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">Kingsway Institute</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">King's Own Institute</td>
                </tr>
                <tr>
                  <td className="section-text">Austria</td>
                  <td className="section-text">University of Vienna</td>
                </tr>
                <tr>
                  <td className="section-text">Canada</td>
                  <td className="section-text">University of Regina</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">Brock University</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">George Brown College</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">IBT College</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">Lakehead University</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">
                    Memorial University of Newfoundland
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">
                    ILI - International Language Institute (ILI)
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">
                    Nova Scotia International Schools
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">Carleton University</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">Concordia University</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">Dalhousie University</td>
                </tr>

                <tr>
                  <td></td>
                  <td className="section-text">Thompson Rivers University</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">Camosun College</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">Alexander College</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">
                    Brandon University & English Language Institute
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">LSBF</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">
                    Canadian College of English Language
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="section-text">Cape Breton University</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Right Column - Contact Form */}
         <RightColumForm/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FindUniversity
