import React from 'react';
import Banner from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavBar from '../Component/Navbar/Navbar';
import RightColumForm from '../Component/RightColumForm';

function StudyAborad() {
  return (
    <div>
      <NavBar />
      <Banner title="Why Study Abroad" text="Study Abroad" />

      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Best Experiences about Studying Abroad: Why Students Should
              Consider It
            </h2>
            <p className="section-text">
              Studying abroad is a transformative experience that offers
              numerous benefits, helping students to grow both professionally
              and personally. Here are some of the top reasons why students
              should consider studying in a foreign country:
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Cultural Immersion
            </h4>
            <p className="section-text">
              Studying abroad exposes students to different cultures,
              traditions, and ways of life. This immersion helps students to
              broaden their perspectives, adapt to new environments, and grow as
              individuals by experiencing life through a global lens.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Academic Excellence
            </h4>
            <p className="section-text">
              Many students have the opportunity to study at top-ranked
              universities, gaining access to world-class resources and learning
              from leading experts in their field. This exposure often enhances
              their academic qualifications, making them more competitive in the
              global job market.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Personal Growth
            </h4>
            <p className="section-text">
              Studying abroad pushes students out of their comfort zone,
              presenting them with challenges they may not face in their home
              country. Learning to adapt to a new culture, environment, and
              academic system fosters resilience, independence, and a sense of
              responsibility.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Language Proficiency
            </h4>
            <p className="section-text">
              Living in a foreign country offers students the chance to learn or
              perfect a new language. By engaging in daily interactions in a
              different language, students can achieve fluency much faster.
              Mastering another language can also open up new career
              opportunities and enhance employability.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Global Networking
            </h4>
            <p className="section-text">
              One of the most valuable aspects of studying abroad is the
              opportunity to build an international network of friends, peers,
              professors, and professionals. These connections often lead to
              valuable career opportunities, collaborations, and lifelong
              friendships across the globe.
            </p>
            <p className="section-text">
              By embracing these experiences, students not only gain academic
              knowledge but also acquire the skills and perspectives necessary
              to thrive in a globalized world. Studying abroad is an investment
              in personal development, offering experiences that shape both
              career and character.
            </p>
          </div>

          {/* Right Column - Contact Form */}
          <RightColumForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StudyAborad
