import React from 'react';
import BannerComponent from '../Component/BannerComponent';
import Footer from '../Component/Footer/Footer';
import NavbarComponent from '../Component/Navbar/Navbar';
import RightSideFrom from '../Component/RightColumForm'

function DealingWithStreet() {
  return (
    <div>
      <NavbarComponent />
      <BannerComponent title="Dealing with Stress" text="Dealing with Stress" />
      <div className="container mt-5">
        <div className="row">
          {/* Left Column - Text Content */}
          <div className="col-md-7">
            <h2 className="section-title" style={{ color: "black" }}>
              Navigating Stress While Studying Abroad: Strategies for Success
            </h2>
            <p className="section-text">
              Studying abroad is an incredible opportunity, but it can also come
              with challenges like stress, homesickness, and cultural
              adjustment. Below are strategies that can help international
              students manage stress effectively:
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Build self-awareness:
            </h4>
            <p className="section-text">
              Identify Stress Triggers: Take time to reflect on what situations
              or challenges cause stress. Whether it's academic pressure,
              language barriers, or adjusting to a new culture, understanding
              your stressors can help you develop coping strategies.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Maintain A Healthy Routine:
            </h4>
            <p className="section-text">
              Balanced Lifestyle: Prioritize your well-being by maintaining a
              healthy diet, getting enough sleep, and exercising regularly.
              Physical fitness is key to managing stress and keeping your energy
              levels high for academic and personal activities.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Practice Mindfulness And Relaxation Techniques:
            </h4>
            <p className="section-text">
              Mindfulness & Meditation: Incorporating mindfulness practices such
              as meditation, deep breathing, or yoga into your routine can help
              you stay calm and centered. These techniques help focus your mind
              and manage stress effectively.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Set Realistic Goals:
            </h4>
            <p className="section-text">
              Manage Expectations: It's common to feel the pressure to excel in
              every area when studying abroad, but it's important to set
              realistic goals. Break larger tasks into smaller, manageable steps
              to avoid feeling overwhelmed.
            </p>

            <h4 className="section-title" style={{ color: "black" }}>
              Stay Organized:
            </h4>
            <p className="section-text">
              Effective Time Management: Organization is key to reducing
              academic-related stress. Utilize tools like task planners, online
              calendars, and to-do lists to stay on top of assignments, tests,
              and deadlines. This helps avoid last-minute rushes and panic.
            </p>
          </div>

          {/* Right Column - Contact Form */}
          <RightSideFrom />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default DealingWithStreet
